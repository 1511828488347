const Workshop = [
  {
    type: 'HTML-CSS-JS',
    name: 'Netflix',
    imagePath: '/cardImage/netflix.png',
    href: 'https://playful-crisp-9a9d86.netlify.app/',
  },
  {
    type: 'HTML-CSS-JS',
    name: 'Web basic',
    imagePath: '/cardImage/web-html-css-js.png',
    href: 'https://superlative-pony-6e3711.netlify.app/',
  },
  {
    type: 'React',
    name: 'Shopping Cart',
    imagePath: '/cardImage/shopping-cart.png',
    href: 'https://silver-douhua-54158f.netlify.app/',
  },
  {
    type: 'React',
    name: 'Search Filter',
    imagePath: '/cardImage/search-filter.png',
    href: 'https://ubiquitous-lebkuchen-9a5e55.netlify.app/',
  },
  {
    type: 'React',
    name: 'Accordion',
    imagePath: '/cardImage/accordion.png',
    href: 'https://voluble-druid-24bece.netlify.app/',
  },
  {
    type: 'React',
    name: 'Calculator',
    imagePath: '/cardImage/calculator.png',
    href: 'https://beamish-gecko-d514b8.netlify.app/',
  },
  {
    type: 'React',
    name: 'Convert currency',
    imagePath: '/cardImage/convert-currency.png',
    href: 'https://beamish-lily-088f77.netlify.app/',
  },
  {
    type: 'React',
    name: 'Dark mode',
    imagePath: '/cardImage/dark-mode.png',
    href: 'https://dancing-torrone-dcdefc.netlify.app/',
  },
  {
    type: 'React',
    name: 'Dynamic dropdown',
    imagePath: '/cardImage/dynamic-dropdown.png',
    href: 'https://jolly-concha-3245e6.netlify.app/',
  },
  {
    type: 'React',
    name: 'Form validation',
    imagePath: '/cardImage/form-validation.png',
    href: 'https://glittery-kheer-9c3ff9.netlify.app/',
  },
  {
    type: 'React',
    name: 'Image carousel',
    imagePath: '/cardImage/image-carousel.png',
    href: 'https://hilarious-croissant-2a35b9.netlify.app/',
  },
  {
    type: 'React',
    name: 'Infinite scroll app',
    imagePath: '/cardImage/infinite-scroll-app.png',
    href: 'https://gorgeous-flan-6f46d6.netlify.app/',
  },
  {
    type: 'React',
    name: 'Navigation',
    imagePath: '/cardImage/navigation.png',
    href: 'https://effervescent-muffin-0fbdf7.netlify.app/',
  },
  {
    type: 'React',
    name: 'Pagination',
    imagePath: '/cardImage/pagination.png',
    href: 'https://sprightly-pudding-d08213.netlify.app/',
  },
  {
    type: 'React',
    name: 'Quiz app',
    imagePath: '/cardImage/quiz-app.png',
    href: 'https://lustrous-cactus-a1827e.netlify.app/',
  },
  {
    type: 'React',
    name: 'Spending app',
    imagePath: '/cardImage/spending-app.png',
    href: 'https://capable-dieffenbachia-e2381e.netlify.app/',
  },
  {
    type: 'React',
    name: 'Todolist app (REACT)',
    imagePath: '/cardImage/todolist-app.png',
    href: 'https://fastidious-moxie-67f856.netlify.app/',
  },
  {
    type: 'React',
    name: 'Weather api',
    imagePath: '/cardImage/weather-api.png',
    href: 'https://delicate-platypus-191f3b.netlify.app/',
  },
  {
    type: 'VUE',
    name: 'Todolist app (VUE)',
    imagePath: '/cardImage/todolist-vue.png',
    href: 'https://symphonious-caramel-cb54fe.netlify.app/',
  },
  {
    type: 'VUE(AXIOS)',
    name: 'Todolist app (VUE AXIOS)',
    imagePath: '/cardImage/vuex.png',
    href: 'https://magical-bublanina-e89b32.netlify.app/#/',
  },
];
export default Workshop;
